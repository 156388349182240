import { useEffect, useState } from "react";

import { ChosenOptions } from "../BookingVehicleInfoCard/BookingVehicleInfoCard.props";
import React from "react";
import { SelectVehicleExtrasProps } from "./SelectVehicleExtras.props";
import { SelectedVehicleExtraOptions } from "../../../@types/VehicleOptions";
import VehicleExtraOptions from "../../UI/VehicleExtraOptions";

SelectVehicleExtras.defaultProps = {
  onChange: () => {},
};
function SelectVehicleExtras({
  options,
  onChange,
  insuranceList,
  values = {},
  showExtras,
}: SelectVehicleExtrasProps): JSX.Element {
  // to check if the extras are linked to insurance . if yes, then we will not show that extra .
  let isElementInInsurance = false;
  const [selectedOptions, setSelectedOptions] =
    useState<ChosenOptions["options"]>(values);
  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions, insuranceList]);
  return (
    <div className="flex md:flex-row md:flex-wrap flex-col h-full gap-2">
      {options.map((el, index) => (
        <div key={index}>
          {insuranceList["Booking Insurance"].some((e) => {
            if (e.optionalsCode === el.optionName) {
              isElementInInsurance = true;
              return true; // to break the loop
            } else {
              isElementInInsurance = false;
              return false;
            }
          })}

          {!isElementInInsurance ? (
            <div className="md:w-56 w-full aspect-square h-[8rem] border-solid border-2 border-gray-300 box-border p-2 mx-4 flex">
              <VehicleExtraOptions
                onDecrementClick={() => {
                  setSelectedOptions((prev) => {
                    const optionName = el.optionName;
                    if (!prev[optionName]) {
                      return { ...prev };
                    }
                    let quantity = prev[optionName].quantity;
                    quantity--;
                    prev[optionName].quantity = Math.max(quantity, 0);
                    prev[optionName].name = el.optionName;
                    prev[optionName].price = el.pricePerDayInUSD;
                    if (!prev[optionName].quantity) {
                      delete prev[optionName];
                    }
                    return { ...prev };
                  });
                }}
                onIncrementClick={() => {
                  setSelectedOptions((prev) => {
                    let maxAllowed: number = 1;
                    const optionName = el.optionName;
                    if (el.maxAllowed) {
                      maxAllowed = el.maxAllowed;
                    }
                    if (!(prev[optionName] && prev[optionName].quantity)) {
                      prev[optionName] = {};
                      prev[optionName].name = optionName;
                      prev[optionName].quantity = 0;
                      prev[optionName].price = options[index].pricePerDayInUSD;
                    }

                    let quantity = prev[optionName].quantity;
                    quantity++;
                    prev[optionName].quantity = Math.min(quantity, maxAllowed);
                    return { ...prev };
                  });
                }}
                {...el}
                value={
                  selectedOptions[el.optionName]
                    ? selectedOptions[el.optionName].quantity || 0
                    : 0
                }
                showExtras={showExtras}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
}
export { SelectVehicleExtras as default };
export { SelectVehicleExtras };
