import { CloseTwoTone, DoneTwoTone } from "@mui/icons-material";
import { AppStandardButton as AppButton } from "../../UI/AppButtons";
import { InsuranceCardProps } from "./Insurance.props";
import React from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import TableContainer from "@mui/material/TableContainer";
import { Paper, TableFooter } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

function InsuranceCardsLayout({
  insurances,
  onSelected,
  selectedInsuranceIndex = -1,
}: InsuranceCardProps) {
  const allInsuranceFeatures: Record<string, boolean> = {};
  const allInsurances = insurances.map((el) => {
    const availableInsurances = el.features.reduce((prev, current) => {
      const feature = current.toLowerCase();
      prev[feature] = true;
      allInsuranceFeatures[feature] = true;
      return prev;
    }, {});
    return { ...el, availableInsurances };
  });
  const { t: translate } = useTranslation();
  const secondaryTextColor = " text-blue-600 ";

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" sx={{ border: 1 }}>
          <TableHead sx={{ border: 1 }}>
            <TableRow>
              <TableCell sx={{ border: 1 }}>{t("Pricing Table")}</TableCell>
              {allInsurances.map((el, index) => (
                <TableCell key={index} sx={{ border: 1 }}>
                  {el.isRecommended && (
                    <img
                      src="/assets/images/Favo-icon@2x.png"
                      alt={""}
                      className="h-12 w-12"
                    />
                  )}
                  {el.name}
                  <p className={secondaryTextColor + "sm:text-md font-normal"}>
                    {el.pricePerDayInUSD}
                  </p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ border: 1 }}>
            {Object.keys(allInsuranceFeatures).map((el, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
              >
                <TableCell component="th" scope="row" sx={{ border: 1 }}>
                  {el}
                </TableCell>
                {allInsurances.map((insurance, i) => {
                  if (insurance.availableInsurances[el]) {
                    return (
                      <TableCell key={i} sx={{ border: 1 }}>
                        <p className="h-full w-full flex justify-center items-center">
                          <DoneTwoTone className="text-green-800 font-bold" />
                        </p>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell key={i} sx={{ border: 1 }}>
                      <p className="h-full w-full flex justify-center items-center">
                        <CloseTwoTone className="text-red-800" />
                      </p>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell sx={{ border: 1 }}>
                <AppButton
                  clickHandler={() => {
                    onSelected(-1);
                  }}
                  color="gray"
                  className="box-border px-6 text-white"
                >
                  {t("Cancel")}
                </AppButton>
              </TableCell>
              {allInsurances.map((el, index) => (
                <TableCell key={index} sx={{ border: 1 }}>
                  <AppButton
                    color={
                      selectedInsuranceIndex == index ? "success" : "primary"
                    }
                    clickHandler={() => {
                      onSelected(index);
                    }}
                    className="box-border px-6"
                  >
                    {t("Choose")}
                  </AppButton>
                </TableCell>
              ))}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
export { InsuranceCardsLayout };
