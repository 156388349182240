import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import React, { ForwardedRef, forwardRef, useState } from "react";

import { AppStandardMultipleSelectionProps } from "./AppMultiSelection.props";
import { useMultiSelectCheckBoxStyles } from "./AppMultiSelection.style";

const AppStandardMultiSelectCheckBox = forwardRef(
  function AppStandardMultiSelectCheckBox(
    {
      value = [],
      options,
      header = "",
      required = false,
      onChange = () => {},
    }: AppStandardMultipleSelectionProps,
    ref: ForwardedRef<HTMLInputElement>
  ) {
    const styles = useMultiSelectCheckBoxStyles();
    const [selectedOptions, setSelectedOptions] = useState<string[]>(
      value || []
    );
    async function handleClick(value: string) {
      let temp: string[] = selectedOptions || [];
      if (!selectedOptions?.includes(value)) {
        temp.push(value);
      } else {
        temp = temp.filter((el) => el != value);
      }
      setSelectedOptions(() => [...temp]);
      onChange(temp);
    }
    return (
      <FormControl required={required}>
        <FormLabel>{header}</FormLabel>
        {/* <h2 className="font-bold text-lg pb-4" ref={ref}>
            {header} HELLO
          </h2> */}
        <FormGroup className={styles.default}>
          {options?.map((el, index) => {
            let label: string = "";
            let value: string = "";
            if (typeof el == "string") {
              value = el;
              label = el;
            } else {
              value = el.value;
              label = el.label;
            }
            return (
              <div className="flex flex-row h-fit" key={index}>
                <FormControlLabel
                  aria-required={true}
                  label={label}
                  className="select-none"
                  ref={ref}
                  value={value}
                  control={
                    <Checkbox
                      required={!selectedOptions.length && required}
                      value={el}
                      sx={{
                        color: "#bb3333",
                        "&.Mui-checked": {
                          color: "#bb3333",
                        },
                      }}
                      checked={selectedOptions.includes(value)}
                      onChange={async (e) => {
                        handleClick(value).then();
                      }}
                    />
                  }
                />
              </div>
            );
          })}
        </FormGroup>
      </FormControl>
    );
  }
);
export { AppStandardMultiSelectCheckBox };
