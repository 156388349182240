import {
  createSearchParams,
  NavigateFunction,
  useNavigate,
} from "react-router-dom";

import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import BookingForm from "../../components/Layouts/BookingForm/BookingForm";
import { PageConfigGetters } from "../../store/PageConfigurations/getters";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BookingApi from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import { useTranslation } from "react-i18next";
import CollapsibleNavbar from "../../components/Layouts/ReservedAreaDashboard/CollapseNavbar";

function HomePage(): JSX.Element {
  const { t: translate } = useTranslation();
  const homePageConfig = PageConfigGetters.getHomePageConfig();
  const BookingFormConfig = PageConfigGetters.getBookingFormConfig();
  const dispatch = useDispatch();
  const routerNavigation: NavigateFunction = useNavigate();
  useEffect(() => {
    dispatch(BOOKING_ACTIONS.setSessionExpiryTime(0));
  }, []);
  const [showBooking, setShowBooking] = useState<boolean>(true);
  useEffect(() => {
    BookingApi.getAppSetting().then((e: any) => {
      setShowBooking(e.booking);
    });
  }, [showBooking]);
  const [userId] = useState(localStorage.getItem("userID"));
  const [reservedArea, setReservedArea] = useState(false);

  useEffect(() => {
    if (userId != null && userId != "null" && userId != "") {
      setReservedArea(true);
      BookingApi.getUserProfileByUserId(userId).then((e) => {
        if (e.data.status == "Not Found") {
          setReservedArea(false);
        } else {
          setReservedArea(true);
        }
      });
    } else {
      setReservedArea(false);
    }
  }, [userId]);

  return (
    <div>
      {showBooking ? (
        <div className="flex">
          <div className="w-[100%] rounded-sm pt-12 pb-12 h-[100%]">
            {BookingFormConfig.fields && (
              <>
                {reservedArea && <CollapsibleNavbar callFrom="dashboard" />}
                <BookingForm
                  className="grow px-8"
                  options={BookingFormConfig.fields || []}
                  onSubmit={(e) => {
                    dispatch(
                      BOOKING_ACTIONS.setBookingInformation({
                        ...e,
                      })
                    );

                    const searchParams = createSearchParams({
                      dropOffDateAndTime: e.dropOffDateAndTime,
                      pickUpDateAndTime: e.pickUpDateAndTime,
                      pickUpLocation: e.pickUpLocationDetails.code,
                      dropOffLocation: e.dropOffLocationDetails.code,
                      reservationSource: e.reservationSource.code,
                      macroGroupName: e.macroGroupName,
                      agreementCoupon: e.agreementCoupon,
                      showPics: "true",
                      showOptionalImage: "true",
                      showVehicleParameter: "true",
                      showVehicleExtraImage: "true",
                      showBookingDiscount: "true",
                      isYoungDriverAge: e.isYoungDriverAge,
                      isSeniorDriverAge: e.isSeniorDriverAge,
                      noFeeAge: e.noFeeAge,
                      isAgeLimitSelected: e.isAgeLimitSelected,
                      callFrom: "reservedArea",
                      isMediumTermBooking: "false",
                    }).toString();
                    routerNavigation("/choose/vehicle?" + searchParams);
                  }}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <NotAuthorisedPage />
      )}
    </div>
  );
}
export default HomePage;
