import InfiniteScroll from "react-infinite-scroll-component";
import { BookingVehicleInfoCard } from "../BookingVehicleInfoCard/BookingVehicleInfoCard";
import React, { useEffect, useRef, useState } from "react";
import { Vehicle } from "../../../@types/DTO/Vehicles";
import { ShowVehiclesProps } from "./ShowVehiclesProps";
import { Box, CircularProgress } from "@mui/material";
import { start } from "repl";
import "./Scrollbar.css";
import BookingGetters from "../../../store/onlineBooking/getters";

export function ShowVehicles({
  vehiclesFilter = {
    status: {},
    category: {},
    type: {},
    TotalSeats: [],
    MacroGroup: {},
    Transmission: {},
  },
  elementsToShowInOnePage = 3,
  allVehicles = [],
  onVehicleSelect = () => {},
  isMediumTerm = "",
}: ShowVehiclesProps): JSX.Element {
  const [availableVehicles, setAvailableVehicles] = useState<Vehicle[]>([]);
  const [availableVehiclesPageNumber, setAvailableVehiclesPageNumber] =
    useState<number>(1);
  const [showingVehicles, setShowingVehicles] = useState<Vehicle[]>([]);
  const [isLoadingMoreVehicles, setIsLoadingMoreVehicles] =
    useState<boolean>(false);
  const infiteScrollContainer = useRef(null);

  function addAvailableVehicles() {
    const startIndex = availableVehiclesPageNumber * elementsToShowInOnePage;
    const endIndex = startIndex + elementsToShowInOnePage;
    const result: Vehicle[] = availableVehicles.slice(0, endIndex);
    return result;
  }

  function requestParentResize() {
    const iframeHeight = document.body.scrollHeight;
    window.parent.postMessage(
      {
        event_id: "iframeHeight",
        data: {
          iframeHeight: iframeHeight,
        },
      },
      "*"
    );
  }
  function loadMoreVehicles() {
    let result: Vehicle[] = [];
    setIsLoadingMoreVehicles(true);
    result = [...addAvailableVehicles()];
    if (result.length <= availableVehicles.length) {
      setShowingVehicles([...result]);
      setAvailableVehiclesPageNumber((prev) => prev + 1);
    }
    setIsLoadingMoreVehicles(false);
  }
  function segregateData() {
    setAvailableVehicles(
      (allVehicles.filter((e) => e.Status.toLowerCase() === "available") || [])
        .filter((vehicle) => {
          const length = Object.keys(vehiclesFilter.type).length;
          if (length < 1) {
            return true;
          }
          const fuelType =
            vehicle.Vehicle?.fuelType?.description?.toLowerCase() || "";
          return fuelType in vehiclesFilter.type;
        })
        .filter((e) => {
          if (!vehiclesFilter.TotalSeats.length) {
            return true;
          }
          return vehiclesFilter.TotalSeats.filter(
            (v) => v == (e.Vehicle?.seats.toString() || "0")
          ).length;
        })
        .filter((e) => {
          if (!Object.keys(vehiclesFilter.Transmission).length) {
            return true;
          }
          const selectedTransmission = vehiclesFilter.Transmission;
          const transmissionDescription =
            e.Vehicle?.transmission?.description?.toLowerCase() || "";
          return Object.keys(selectedTransmission)
            .map((e) => e.toLowerCase())
            .includes(transmissionDescription.toLocaleLowerCase());
        })
        .sort((a, b) => {
          const priceA = a.TotalCharge?.RateTotalAmount || 0;
          const priceB = b.TotalCharge?.RateTotalAmount || 0;

          if (priceA === 0) return 1;
          if (priceB === 0) return -1;

          return priceA - priceB;
        })
    );
  }
  useEffect(() => {
    segregateData();
    RefreshData();
  }, [allVehicles]);
  useEffect(() => {
    segregateData();
    RefreshData();
  }, [vehiclesFilter]);
  useEffect(() => {
    RefreshData();
  }, [availableVehicles]);

  const AppSettings = BookingGetters.getAppSettings();
  useEffect(() => {
    if (AppSettings?.showInIframe) {
      requestParentResize();
    }
  }, [showingVehicles]);

  function RefreshData() {
    setShowingVehicles([]);
    setAvailableVehiclesPageNumber(1);
    loadMoreVehicles();
  }

  return (
    <>
      {!isLoadingMoreVehicles && (
        <div
          className="font-bold text-center text-black h-full flex flex-col gap-4 w-full"
          ref={infiteScrollContainer}
        >
          {showingVehicles.length ? (
            showingVehicles.map((el: Vehicle, index) => {
              return (
                <div
                  key={index}
                  className="border-solid border-2 border-gray-300 rounded cursor-pointer hover:border-blue-500 hover:border-3"
                >
                  <BookingVehicleInfoCard
                    extras={
                      el.optionals
                        ? el.optionals?.map((e) => {
                            return {
                              ...e,
                              iconUrl: e.Equipment.optionalImage || "#",
                              maxAllowed: e.Equipment.isMultipliable ? 20 : 1,
                              optionName: e.Equipment?.EquipType || "",
                              pricePerDayInUSD: (
                                e.Charge.Amount || 0
                              ).toString(),
                              details: e.Equipment?.Description || "",
                            };
                          })
                        : []
                    }
                    discount={el.Vehicle?.payNowDis || 0}
                    status={el.Status}
                    modelYear=""
                    vehicleGroup={el.Vehicle?.groupPic?.nationalCode || ""}
                    priceDetails={{
                      payNow: (el.TotalCharge?.RateTotalAmount || 0).toString(),
                      payLater: (
                        el.TotalCharge?.RateTotalAmount || 0
                      ).toString(),
                      feeAmout: (el?.payLaterFee || 0).toString(),
                      payLaterFee: (
                        el?.payLaterFee + el?.TotalCharge?.RateTotalAmount
                      ).toString(),
                    }}
                    specifications={
                      el.Vehicle?.vehicleParameter
                        .sort((e1, e2) => {
                          return e1["position :"] - e2["position :"];
                        })
                        .map((e) => {
                          return {
                            iconUrl: e["fileUrl :"],
                            name: e["name :"],
                            value: e["name :"],
                          };
                        }) || []
                    }
                    topFeature={""}
                    vehicleImages={[
                      el.Vehicle?.vehicleGroupPic || "",
                      ...(el.Vehicle?.vehicleExtraImage || []).filter((e) => {
                        return !!e;
                      }),
                    ]}
                    vehicleName={el.Vehicle?.VehMakeModel?.Name || ""}
                    payLater={"Pay Later"}
                    disclaimer={
                      "Le opzioni di questo veicolo da consegnare possono variare"
                    }
                    payNow={"Pay Now"}
                    selectExtra={"Choose Insurance"}
                    closeExtra={"Chiudi extra"}
                    viewAllSpecifications={"Visualizza tutte le specifiche"}
                    moveToPayment={(e) => {
                      onVehicleSelect({
                        vehicleDetails: el,
                        paymentDetails: e,
                      });
                    }}
                    isMediumTerm={isMediumTerm}
                  />
                </div>
              );
            })
          ) : (
            <div style={{ color: "white" }}>No Vehicles Available</div>
          )}
        </div>
      )}
      {isLoadingMoreVehicles && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
