import AppStandardButton from "../../components/UI/AppButtons";
import { BookingInformationRow } from "../../components/Layouts/BookingInformation/BookingInformation";
import React, { useEffect } from "react";
import { t } from "i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BookingApi } from "../../API/Apis/BookingApi";
import { NexiChargeTransactionDetails } from "../../@types/Booking/nexiTransactionDetails";

export function WireTransferConfirm() {
  const navigateTo = useNavigate();
  const [queryParams] = useSearchParams();
  const isMediumTerm = queryParams.get("mediumTerm");
  const navigateToHomepage = () => {
    if (isMediumTerm == "true") {
      navigateTo("/mediumTerm");
    } else {
      navigateTo("/");
    }
  };
  return (
    <section className="min-h-[80vh] box-border px-44  flex flex-col border-t-2 border-solid border-grey-300">
      <header className="grow flex flex-col items-center justify-center gap-4 box-border py-8">
        <img src="/assets/images/success-icon@2x.png" className="h-36 w-36" />
        <h1 className="text-3xl font-bold text-[#2267ae] py-2">
          {t("Congratulations! Your Booking Is Completed")}
        </h1>
        <div className="flex flex-col-2 ">
          {" "}
          <AppStandardButton
            onClick={navigateToHomepage}
            className={"w-fit py-4 mx-8"}
            variant="outlined"
            color="addInsuranceButton"
            style={{ borderRadius: "5" }}
          >
            <span className="normal-case text-[#2267ae] p-1 text-lg">
              {" "}
              Homepage
            </span>
          </AppStandardButton>
          <AppStandardButton
            onClick={navigateToHomepage}
            className={"w-fit py-4 mx-8"}
            color="addInsuranceButton"
            variant="outlined"
            style={{ borderRadius: "5" }}
          >
            <span className="normal-case text-[#2267ae] p-1 text-lg">
              {" "}
              Dashboard
            </span>
          </AppStandardButton>
        </div>
      </header>
      <main></main>
      <footer className="text-gray-600 box-border pb-32">
        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
