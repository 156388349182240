import React from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { Avatar } from "@mui/material";
function ProfileNavigation() {
  const navigateTo = useNavigate();
  const isLogged = localStorage.getItem("isUserLoggedin");

  function onLogout() {
    if (isLogged == "true") {
      localStorage.setItem("isUserLoggedin", "false");
      localStorage.removeItem("userID");
      localStorage.removeItem("customerId");
      navigateTo("/signin");
    } else {
      alert("you are already logged out");
    }
  }
  function handlePersonalInfo() {
    navigateTo("/profile");
  }

  function handleChangePassword() {
    navigateTo("/changePassword");
  }
  function handleCreateBooking() {
    navigateTo("/");
  }
  return (
    <div
      className="text-[#020D53] p-12 shadow-[5px_0px_16px_3px_#00000024]"
      style={{ height: "100vh" }}
    >
      <div style={{ marginBottom: "20px" }}>
        <ul>
          <li
            className={`flex items-center gap-4 rounded-2xl text-[18px]
            }`}
          >
            <img
              className="h-4 cursor-pointer"
              src="/assets/images/backIcon.png"
              alt="back"
              onClick={handleCreateBooking}
            />
            {t("My Profile")}
          </li>
        </ul>
      </div>
      <hr></hr>
      <div className="mt-6">
        <ul className="flex flex-col gap-4 mt-8 text-[20px]">
          <li
            className={`flex items-center gap-4 hover:bg-[#EFEFEF] cursor-pointer rounded-2xl text-[18px]
            }`}
            onClick={handlePersonalInfo}
          >
            <img
              className="h-6"
              src="/assets/images/profile.png"
              alt="CreateBooking"
            />
            {t("Personal information")}
          </li>
          <li
            className={`flex items-center gap-4 hover:bg-[#EFEFEF] cursor-pointer rounded-2xl text-[18px]
            }`}
            onClick={handleChangePassword}
          >
            <img
              className="h-6"
              src="/assets/images/reset-password.png"
              alt="CreateBooking"
            />
            {t("Change password")}
          </li>
          <li
            onClick={onLogout}
            className={`flex items-center gap-4 hover:bg-[#EFEFEF] cursor-pointer rounded-2xl text-[18px]
            }`}
          >
            <img
              className="h-6"
              src="/assets/images/logout.png"
              alt="CreateBooking"
            />
            {t("Logout")}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ProfileNavigation;
