import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BookingApi from "../../../API/Apis/BookingApi";
import { t } from "i18next";
import { ArrowDropDownOutlined } from "@mui/icons-material";

function BookingDetalisListSideNavigation() {
  const navigateTo = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const handleProfileTabClick = (tab) => {
    setActiveTab(tab === activeTab ? "" : tab);
  };
  const [userData, setUserData] = useState([]);
  const [isActive, setIsActive] = useState("");
  const className =
    "flex items-center gap-4 hover:bg-[#EFEFEF] cursor-pointer rounded-2xl";

  const handleTabClick = (tab) => {
    setIsActive(tab);
  };

  useEffect(() => {
    const id: any = localStorage.getItem("userID");
    BookingApi.getUserProfileByUserId(id).then((e) => {
      setUserData(e.data.onlineUser[0]);
    });
  }, []);

  function OncustomerDetalis() {
    navigateTo("/customerDetailsList");
  }
  function OnProfile() {
    navigateTo("/profile2");
  }

  function handleReservationListEvent() {
    navigateTo("/myReservedBookings");
  }

  function handleInvoiceListEvent() {
    navigateTo("/myInvoices");
  }

  function handleCreateBooking() {
    navigateTo("/");
  }

  function handlePersonalInfo() {
    navigateTo("/profile");
  }

  function handleChangePassword() {
    navigateTo("/changePassword");
  }

  function handleLogout() {
    localStorage.setItem("isUserLoggedin", "false");
    localStorage.removeItem("userID");
    localStorage.removeItem("customerId");
    navigateTo("/signin");
  }

  return (
    <div
      className="text-[#000] p-12 shadow-[5px_0px_16px_3px_#00000024]"
      style={{ height: "100vh" }}
    >
      <div className="pb-8 pt-2">
        <ul className="flex items-center gap-4 mt-4">
          <li>
            <Avatar
              alt={`${userData.firstName}`}
              src="/assets/images/user.png"
            />
          </li>
          <li
            className={`font-poppins_regular text-[20px] ${
              isActive == "tab0" ? "bg-gray-700" : ""
            }`}
          >
            {userData.firstName + " " + userData.lastName}
          </li>
        </ul>
      </div>
      <hr></hr>
      <div className="mt-6">
        <ul className="flex flex-col gap-6 text-base ">
          <li
            style={{ padding: "4px" }}
            onClick={() => {
              handleCreateBooking();
              handleTabClick("tab1");
            }}
            className={className}
          >
            <img
              className="h-6"
              src="/assets/images/createBooking.png"
              alt="CreateBooking"
            />
            {t("Create Booking")}
          </li>
          <li
            style={{ padding: "4px" }}
            onClick={() => {
              OncustomerDetalis();
              handleTabClick("tab2");
            }}
            className={className}
          >
            <img
              className="h-6"
              src="/assets/images/customer.details.png"
              alt="Customer Details"
            />
            {t("Customer Details")}
          </li>
          <li
            style={{ padding: "4px" }}
            onClick={() => {
              handleReservationListEvent();
              handleTabClick("tab3");
            }}
            className={className}
          >
            <img
              className="h-6"
              src="/assets/images/bookingicon.png"
              alt="List of reservation"
            />
            {t("List of reservation")}
          </li>
          <li
            style={{ padding: "4px" }}
            onClick={() => {
              handleInvoiceListEvent();
              handleTabClick("tab4");
            }}
            className={className}
          >
            <img
              className="h-6"
              src="/assets/images/invoiceicon.png"
              alt="List of invoice"
            />
            {t("List of invoice")}
          </li>
          <li
            style={{
              // backgroundColor: "#F0F0F0",
              // borderRadius: "4px",
              padding: "4px",
            }}
          >
            <ul className="flex flex-col gap-6 text-base">
              <li
                className={`flex items-center gap-4 bg-gray cursor-pointer rounded-2xl text-[#CC3333] font-bold
            }`}
                onClick={() => {
                  handleProfileTabClick("profile");
                }}
              >
                <img
                  className="h-5 cursor-pointer"
                  src="/assets/images/profile.png"
                  alt="back"
                />
                {t("My Profile")}
                <ArrowDropDownOutlined style={{ color: "black" }} />
              </li>
              {activeTab === "profile" && (
                <>
                  <li
                    className={className}
                    style={{ marginLeft: "35px", padding: "4px" }}
                    onClick={handlePersonalInfo}
                  >
                    {t("Personal information")}
                  </li>
                  <li
                    className={className}
                    style={{ marginLeft: "35px", padding: "4px" }}
                    onClick={handleChangePassword}
                  >
                    {t("Change password")}
                  </li>
                </>
              )}
            </ul>
          </li>
          <li
            style={{ padding: "4px" }}
            onClick={() => {
              handleLogout();
              handleTabClick("tab5");
            }}
            className={className}
          >
            <img className="h-6" src="/assets/images/logout.png" alt="Logout" />
            {t("Logout")}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BookingDetalisListSideNavigation;
