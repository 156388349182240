import React, { useEffect, useState } from "react";
import BookingDetalisListSideNavigation from "../../components/Layouts/ReservationsCard/BookingDetalisListSideNavigation";
import { BookingApi } from "../../API/Apis/BookingApi";
import NotAuthorisedPage from "../notAuthorisedPage";
import InvoiceCard from "./InvoiceCard";
import { t } from "i18next";
import { AppStandardButton } from "../../components/UI/AppButtons";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

export default function MyInvoices() {
  const [showInvoices, setShowInvoices] = useState(true);
  useEffect(() => {
    BookingApi.getAppSetting().then((e) => {
      setShowInvoices(e.reservedArea);
    });
  }, [showInvoices]);
  const [searchInvoice, setSearchInvoice] = useState("");
  const [finalSearchInvoice, setFinalSearchInvoice] = useState("");
  const handleSearchInvoice = () => {
    setFinalSearchInvoice(searchInvoice);
  };
  const setSearchInvoiceValue = (event) => {
    setSearchInvoice(event.target.value);
  };
  return (
    <div>
      {showInvoices ? (
        <div className="flex">
          <div className="!w-780">
            <BookingDetalisListSideNavigation />
          </div>
          <div className="w-[73%] rounded-sm pt-12">
            <div className="text-[#333] px-8 max-w-screen-xl m-auto">
              <div className="flex justify-between items-center">
                <p className="text-[24px] font-medium leading-snug">
                  {t("Invoice List")}
                </p>
                <div className="flex items-center">
                  <TextField
                    label="Search Invoice"
                    variant="outlined"
                    className="mr-4"
                    value={searchInvoice}
                    onChange={setSearchInvoiceValue}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={handleSearchInvoice}
                          style={{ cursor: "pointer" }}
                        >
                          <Search />
                        </InputAdornment>
                      ),
                      style: {
                        height: "40px",
                        borderRadius: "10px",
                        paddingRight: "5px",
                      },
                    }}
                  />

                  <AppStandardButton
                    href="/"
                    className="ml-4"
                    variant="contained"
                    style={{
                      width: "150px",
                      backgroundColor: "#CC3333",
                      borderRadius: "10px",
                    }}
                  >
                    <span className="normal-case">Book a Car</span>
                  </AppStandardButton>
                </div>
              </div>
              <p className="text-[#838181] text-[16px]">
                {t("View all your Invoices here.")}
              </p>
            </div>
            <div className={"block p-3"}>
              <InvoiceCard searchInvoice={finalSearchInvoice}></InvoiceCard>
            </div>
          </div>
        </div>
      ) : (
        <NotAuthorisedPage />
      )}
    </div>
  );
}
