import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { BookingApi } from "../../API/Apis/BookingApi";
import { t } from "i18next";
import TablePagination from "@mui/material/TablePagination";
import DefaultPage from "../../components/Layouts/DefaultPage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { AppStandardButton } from "../../components/UI/AppButtons";

export default function InvoiceCard(props) {
  const [invoiceList, setInvoiceList] = useState([]);
  const [allInvoiceList, setAllInvoiceList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const requestOnClose = () => {
    setOpenModal(false);
    setModalMessage("");
  };
  const [searchInvoice, setSearchInvoice] = useState("");
  const [finalSearchInvoice, setFinalSearchInvoice] = useState("");
  const handleSearchInvoice = () => {
    setFinalSearchInvoice(searchInvoice);
  };
  const setSearchInvoiceValue = (event) => {
    setSearchInvoice(event.target.value);
  };
  const handlePageChange = (event: unknown, page: number) => {
    setPage(page);
  };

  const handleRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const downloadInvoice = (invoiceId) => {
    if (invoiceId == "No Invoice Found") {
      setModalMessage("No Invoice Found");
      setOpenModal(true);
    } else {
      BookingApi.downloadInvoice(invoiceId).then((e) => {
        if (e.data.status == "error") {
          setModalMessage(e.data.errorMessage);
          setOpenModal(true);
        } else {
          const url = e.data;
          window.open(url, "_blank");
        }
      });
    }
  };
  useEffect(() => {
    if (props.searchInvoice != null && props.searchInvoice != "") {
      setInvoiceList(allInvoiceList);
      const searchTerm = props.searchInvoice
        .trim()
        .replace(/\s+/g, "")
        .toLowerCase();
      const filteredInvoices = allInvoiceList.filter((invoice) => {
        const prefix = invoice?.prefix.toLowerCase();
        const number = invoice?.invoiceNumber?.toString();
        return (
          prefix.includes(searchTerm) ||
          number.includes(searchTerm) ||
          searchTerm.includes(prefix + number)
        );
      });
      setTotalInvoices(filteredInvoices.length);
      setInvoiceList(filteredInvoices);
    } else {
      setTotalInvoices(allInvoiceList.length);
      setInvoiceList(allInvoiceList);
    }
  }, [props.searchInvoice]);

  useEffect(() => {
    setLoader(true);
    const id: any = localStorage.getItem("userID");
    const offset = page * rowsPerPage;
    BookingApi.getOnlineUserInvoices(id, offset, rowsPerPage).then((e) => {
      setTotalInvoices(e.data.total);
      setInvoiceList(e.data.invoiceList);
      setAllInvoiceList(e.data.invoiceList);
      setLoader(false);
    });
  }, [page, rowsPerPage]);
  return (
    <>
      <Dialog
        open={openModal}
        onClose={requestOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle style={{ padding: "3rem" }}>{modalMessage}</DialogTitle>

        <DialogActions>
          <AppStandardButton variant="outlined" onClick={requestOnClose}>
            <p className="text-black normal-case">Close</p>
          </AppStandardButton>
        </DialogActions>
      </Dialog>
      {loader ? (
        <Typography className="!text-center  !text-3xl !flex !justify-center  ">
          Loading...
        </Typography>
      ) : (
        <div>
          {invoiceList.length === 0 ? (
            <DefaultPage
              message={"You haven't created any invoices yet."}
            ></DefaultPage>
          ) : (
            <>
              <TableContainer className="!shadow-grey-400 !rounded-2xl !border-solid !border-3 p-4">
                <Table
                  className="!shadow-2xl rounded-2xl !border-solid !border-2 !border-grey-400"
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead className="!bg-[#CC3333] rounded-2xl">
                    <TableRow className="!bg-[#dc1c24] rounded-2xl">
                      <TableCell className="!bg-[#dc1c24] !text-[#fff] !text-[15px] rounded-tl-2xl">
                        {t("Invoice Number")}
                      </TableCell>
                      <TableCell className="!bg-[#dc1c24] !text-[#fff] !text-[15px] ">
                        {t("Start Date")}
                      </TableCell>
                      <TableCell className="!bg-[#dc1c24] !text-[#fff] !text-[15px]">
                        {t("End Date")}
                      </TableCell>
                      <TableCell className="!bg-[#dc1c24] !text-[#fff] !text-[15px]">
                        {t("Invoice Amount")}
                      </TableCell>
                      <TableCell className="!bg-[#dc1c24] !text-[#fff] !text-[15px]">
                        {t("Status")}
                      </TableCell>
                      <TableCell className="!bg-[#dc1c24] !text-[#fff] !text-[15px] rounded-tr-2xl">
                        {t("Download")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceList.map((e: any, i) => (
                      <TableRow key={i}>
                        <TableCell
                          className="!text-[12px] text-[#333] rounded-bl-2xl"
                          component="th"
                          scope="e"
                        >
                          <ul>
                            <li>{e.prefix + "-" + e.invoiceNumber || ""}</li>
                            <li className="text-[#838181] text-[10px]">
                              {e.rentalAgreement != null &&
                              e.rentalAgreement != ""
                                ? "Rental" + " " + e.rentalAgreement
                                : "Reservation" + " " + e.reservation}
                            </li>
                          </ul>
                        </TableCell>
                        <TableCell className="!text-[12px] text-[#333]">
                          {e.startDate || ""}
                        </TableCell>
                        <TableCell className="!text-[12px] text-[#333]">
                          {e.invoiveEndDate || ""}
                        </TableCell>
                        <TableCell className="!text-[12px] text-[#333]">
                          {"€" + " " + e.totInvoice || ""}
                        </TableCell>
                        <TableCell>
                          <div
                            className="!text-[11px] text-[#333]"
                            style={{
                              backgroundColor: e.isPaid
                                ? "lightgreen"
                                : "#FF634748",
                              color: e.isPaid ? "#008741" : "#CC3333",
                              textAlign: "center",
                              borderRadius: "1rem",
                              fontWeight: "bold",
                              padding: "2px",
                            }}
                          >
                            {(e.isPaid ? "Paid" : "Unpaid") || ""}
                          </div>
                        </TableCell>

                        <TableCell>
                          <button
                            className="flex text-[14px] h-70 text-[#FF0000] rounded"
                            onClick={() => downloadInvoice(e.id)}
                          >
                            Invoice
                            <img
                              className="h-3"
                              style={{ marginLeft: "5px" }}
                              src="/assets/images/downloadIcon.png"
                              alt="Invoice Icon"
                            />
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[8]}
                component="div"
                count={totalInvoices}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
